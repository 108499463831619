import React, { useCallback, useEffect, useRef, useState } from 'react';
import useOutsideClick from 'react-cool-onclickoutside';
import cn from 'classnames';
import { useWeb3React } from '@web3-react/core';
import { ApolloQueryResult, gql } from '@apollo/client';

import './TransactionsWidget.scss';

import { PendingTransactionList } from './PendingTransactionList';
import {
  getProtocolTransactionStorage,
  ProtocolTransaction
} from '../../lib/transactions-storage/TransactionsStorage';

import useGraphQuery from 'common/hooks/useGraphQuery';
import Loader from 'common/components/Loader';
import { ApolloClientName } from 'common/components/ApolloClientProvider';
import config from '../../../common/config';

export const activity = {
  isActive: true,
  lastActiveTime: Date.now(),
  IDLE_TIMEOUT: 60000, // 1 minute in milliseconds

  setActive(): void {
    this.isActive = true;
    this.lastActiveTime = Date.now();
  },

  checkIfStillActive(): boolean {
    const now = Date.now();
    if (now - this.lastActiveTime > this.IDLE_TIMEOUT) {
      this.isActive = false;
    }
    return this.isActive;
  }
};

if (typeof window !== 'undefined') {
  const activityEvents = [
    'mousedown',
    'mousemove',
    'keydown',
    'scroll',
    'touchstart'
  ];
  activityEvents.forEach((eventName) => {
    window.addEventListener(eventName, () => activity.setActive(), true);
  });
}

const POLLING_INTERVAL = 60 * 1000;

interface TransactionsWidgetProps {
  lastBlock: number;
  lastSyncedBlock: number;
  pendingTransactions: ProtocolTransaction[];
}

const TransactionsWidget = ({
  lastBlock,
  lastSyncedBlock,
  pendingTransactions
}: TransactionsWidgetProps): JSX.Element | null => {
  const rootElRef = useRef(null);
  const [expanded, setExpaned] = useState<boolean>(true);
  const _lastBlock = Math.max(lastBlock, lastSyncedBlock);

  useOutsideClick(() => setExpaned(false), {
    refs: [rootElRef],
    disabled: pendingTransactions.length === 0
  });

  if (pendingTransactions.length === 0) {
    return null;
  }

  return (
    <div
      ref={rootElRef}
      className={cn('TransactionsWidget', {
        'TransactionsWidget--expanded': expanded
      })}
    >
      <button
        className="TransactionsWidget__trigger"
        onClick={() => setExpaned(!expanded)}
      >
        <Loader /> {pendingTransactions.length}
      </button>
      <div className="TransactionsWidget__list-container">
        <PendingTransactionList
          transactions={pendingTransactions}
          latestBlock={_lastBlock}
          lastSyncedBlock={lastSyncedBlock}
        />
      </div>
    </div>
  );
};

const BLOCK_NUMBER_SUBGRAPH_DOCUMENT = gql`
  query BlockQuery {
    _meta {
      block {
        number
      }
    }
  }
`;

export type BlockNumberSubgraphResponse = {
  _meta: {
    block: {
      number: number;
    };
  };
};

function useBlockNumberSubgraphQuery(): [
  number,
  () => Promise<ApolloQueryResult<BlockNumberSubgraphResponse>>
] {
  const queryResult = useGraphQuery<
    Record<string, unknown>,
    BlockNumberSubgraphResponse
  >({
    refetchOnMount: true,
    enabled: true,
    clientName: ApolloClientName.Subgraph,
    query: BLOCK_NUMBER_SUBGRAPH_DOCUMENT,
    variables: {}
  });

  return [queryResult.data?._meta.block.number || 0, queryResult.refetch];
}

const useTransactionsStorage = (): {
  lastSyncedBlock: number;
  lastBlock: number;
  pendingTransactions: ProtocolTransaction[];
} => {
  const web3React = useWeb3React();
  const [syncedBlockNumber, refetch] = useBlockNumberSubgraphQuery();
  const [transactions, setTransactions] = useState<ProtocolTransaction[]>([]);
  const [lastBlock, setLastBlock] = useState<number>(0);
  const pendingTransactions = transactions.filter(
    (tx) => !tx.confirmed || tx.blockNumber > syncedBlockNumber
  );

  const updateConfirmedTxs = useCallback(async (): Promise<
    ProtocolTransaction[]
  > => {
    const _transactionStorage = web3React.account
      ? getProtocolTransactionStorage(web3React.account)
      : null;
    const transactions =
      _transactionStorage?.load(0).map((tx): Promise<void> => {
        if (tx.confirmed) {
          return Promise.resolve();
        }
        return (
          web3React.provider?.getTransaction(tx.hash).then((tx) => {
            if (tx?.blockNumber) {
              _transactionStorage.confirmTransaction(tx.hash, tx.blockNumber);
            }
          }) || Promise.resolve()
        );
      }) || [];
    await Promise.all(transactions);
    const newTransactions = _transactionStorage?.load(0);
    setTransactions((_transaction) => newTransactions || _transaction);
    return newTransactions || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3React.account]);

  useEffect(() => {
    const _transactionStorage = web3React.account
      ? getProtocolTransactionStorage(web3React.account)
      : null;
    if (_transactionStorage) {
      const id = _transactionStorage.subscribe(() => {
        updateConfirmedTxs();
      });
      return () => _transactionStorage.unsubscribe(id);
    }
  }, [updateConfirmedTxs, web3React.account]);

  useEffect(() => {
    if (web3React.chainId !== config.chainId) return;

    const fetchLatestBlock = async (): Promise<void> => {
      if (!activity.checkIfStillActive() && pendingTransactions.length === 0) {
        return;
      }

      const _currentBlock = (await web3React.provider?.getBlockNumber()) || 0;
      setLastBlock(_currentBlock);
      const txs = await updateConfirmedTxs();
      txs.length && (await refetch());
    };

    fetchLatestBlock();
    const intervalId = setInterval(fetchLatestBlock, POLLING_INTERVAL);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateConfirmedTxs,
    web3React.chainId,
    web3React.account,
    pendingTransactions.length
  ]);

  return {
    lastSyncedBlock: syncedBlockNumber,
    lastBlock,
    pendingTransactions
  };
};

export default function TransactionsWidgetContainer(): JSX.Element {
  const { lastBlock, lastSyncedBlock, pendingTransactions } =
    useTransactionsStorage();

  return (
    <TransactionsWidget
      lastBlock={lastBlock}
      lastSyncedBlock={lastSyncedBlock}
      pendingTransactions={pendingTransactions}
    />
  );
}
