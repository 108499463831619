import { useEffect } from 'react';
import {
  ApolloQueryResult,
  DocumentNode,
  NetworkStatus,
  OperationVariables,
  QueryResult,
  useQuery
} from '@apollo/client';

import { ApolloClientName } from 'common/components/ApolloClientProvider';

export const DEFAULT_POLL_INTERVAL = 30 * 1000; // 30 seconds

export type GraphQueryResult<
  TVariables extends OperationVariables,
  TResponse
> = QueryResult<TResponse, TVariables> & {
  refetching: boolean;
  polling: boolean;
  fetched: boolean;
};

function useGraphQuery<TVariables extends OperationVariables, TResponse>(opts: {
  query: DocumentNode;
  variables: TVariables;
  enabled?: boolean;
  refetchOnMount?: boolean;
  enablePolling?: boolean;
  preservePreviousData?: boolean;
  pollInterval?: number;
  clientName?: ApolloClientName;
  fetchPolicy?: 'cache-first' | 'cache-only' | 'network-only' | 'no-cache';
}): GraphQueryResult<TVariables, TResponse> & {
  refetch: (
    variables?: Partial<TVariables> | undefined
  ) => Promise<ApolloQueryResult<TResponse>>;
} {
  const {
    enabled = true,
    clientName = ApolloClientName.Forta,
    refetchOnMount = true,
    variables,
    enablePolling,
    preservePreviousData = true,
    pollInterval = DEFAULT_POLL_INTERVAL,
    query
  } = opts;

  const queryResult = useQuery<TResponse, TVariables>(query, {
    variables,
    notifyOnNetworkStatusChange: true,
    context: { clientName },
    skip: !enabled,
    pollInterval: enablePolling ? pollInterval : undefined,
    fetchPolicy: opts.fetchPolicy || 'cache-and-network'
  });

  const data = preservePreviousData
    ? queryResult.data || queryResult.previousData
    : queryResult.data;

  useEffect(() => {
    if (refetchOnMount && data && !queryResult.loading) {
      queryResult.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...queryResult,
    data: data,
    refetching: queryResult.networkStatus === NetworkStatus.refetch,
    polling: queryResult.networkStatus === NetworkStatus.poll,
    fetched: !!data,
    refetch: queryResult.refetch
  };
}

export default useGraphQuery;
